define([
    'santa-components',
    'lodash',
    'coreUtils',
    'reactDOM',
    'components',
    'skins',
    'googleMap/skins/skins.json',
    'componentsCore'
], function (
    santaComponents,
    _,
    coreUtils,
    ReactDOM,
    components,
    skinsPackage,
    skinsJson,
    componentsCore
) {
    'use strict';

    const SUPPORTED_LANGS = ['da', 'de', 'en', 'es', 'fr', 'it', 'ja', 'ko', 'nl', 'no', 'pl', 'pt', 'ru', 'sv', 'tr'];
    const LOCALES = {pt: 'pt-BR'};

    const getLanguage = function (props) {
        const languageProperty = props.compProp.language;
        const userLanguage = props.userLanguage;

        const lang = languageProperty === 'userLang' ? userLanguage : languageProperty;
        const language = _.includes(SUPPORTED_LANGS, lang) ? lang : 'en';
        return LOCALES[language] || language;
    };

    function getMapParamsFromProps(props) {
        return _.transform({
            address: props.compData.address,
            addressInfo: props.compData.addressInfo,
            mapType: props.compProp.mapType,
            mapInteractive: props.compProp.mapDragging,
            showZoom: props.compProp.showZoom,
            showStreetView: props.compProp.showStreetView,
            showMapType: props.compProp.showMapType,
            lat: props.compData.latitude,
            long: props.compData.longitude,
            ts: props.structure.layout.width + props.structure.layout.height,
            mapStyle: JSON.stringify(props.compData.mapStyle || [])
        }, function (result, value, key) {
            result[key] = _.isString(value) ? coreUtils.filterHtmlString(value) : value;
        }, {});
    }

    function getIframeUrl(props) {
        const mapProps = getMapParamsFromProps(props);
        const initialProps = _.pick(mapProps, ['lat', 'long', 'address', 'addressInfo', 'showZoom', 'showStreetView', 'showMapType']);
        const initialParams = _.reduce(initialProps, (result, value, key) => result.concat(`&${key}=${value}`), '');
        const queryString = `language=${getLanguage(props)}${initialParams}`;
        return coreUtils.urlUtils.joinURL(props.santaBase || '', `static/external/googleMap.html?${queryString}`);
    }

    /**
     * @class components.GoogleMap
     * @extends {core.skinBasedComp}
     */
    const googleMap = {
        displayName: 'GoogleMap',
        mixins: [componentsCore.mixins.skinBasedComp],
        statics: {
            compSpecificIsDomOnlyOverride: () => false
        },
        propTypes: {
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired,
            structure: santaComponents.santaTypesDefinitions.Component.structure.isRequired,
            santaBase: santaComponents.santaTypesDefinitions.santaBase.isRequired,
            cannotHideIframeWithinRoundedCorners: santaComponents.santaTypesDefinitions.mobile.cannotHideIframeWithinRoundedCorners.isRequired,
            userLanguage: santaComponents.santaTypesDefinitions.WixUserSantaTypes.userLanguage.isRequired
        },
        getInitialState() {
            const state = {};
            if (this.props.cannotHideIframeWithinRoundedCorners()) {
                state.$corners = 'squared';
            }

            this.restartMap(this.props);

            return state;
        },
        componentDidMount() {
            this.iFrameNode = ReactDOM.findDOMNode(this.refs.iframe);
            this.iFrameNode.onload = function () {
                const params = getMapParamsFromProps(this.props);
                this.updateMapParams(params);
            }.bind(this);
        },
        componentWillReceiveProps(newProps) {
            const newMapProperties = getMapParamsFromProps(newProps);

            const shouldUpdateMapParams = getLanguage(this.props) === getLanguage(newProps);
            if (shouldUpdateMapParams) {
                this.updateMapParams(newMapProperties);
            } else {
                this.restartMap(newProps);
            }
        },
        updateMapParams(params) {
            const iFrameNode = ReactDOM.findDOMNode(this.refs.iframe);
            iFrameNode.contentWindow.postMessage(JSON.stringify(params), '*');
        },
        restartMap(props) {
            this.iframeUrl = getIframeUrl(props);
            this.a11yTitle = coreUtils.translationsLoader.getTranslation('component_label', getLanguage(props), 'COMPONENT_LABEL_googleMapsTitle');
        },
        getMapParamsFromProps,
        getSkinProperties() {
            return {
                '': {
                    tabIndex: 0,
                    title: this.a11yTitle,
                    'aria-label': this.a11yTitle
                },
                'mapContainer': {
                    key: 'mapContainer',
                    children: [
                        santaComponents.utils.createReactElement('iframe', {
                            ref: 'iframe',
                            'data-src': this.iframeUrl,
                            width: '100%',
                            height: '100%',
                            frameBorder: '0',
                            scrolling: 'no',
                            title: this.a11yTitle,
                            'aria-label': this.a11yTitle
                        })
                    ]
                }
            };
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.GoogleMap', googleMap);
    components.translationRequirementsChecker.registerCommonLanguageRequirement(
        'wysiwyg.viewer.components.GoogleMap',
        (siteData, compInfo) => _.get(compInfo, ['properties', 'language']));

    skinsPackage.skinsMap.addBatch(skinsJson);

    return googleMap;
});
